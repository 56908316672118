import React, {memo} from "react"
import {Container, Row, Col} from 'react-bootstrap';

const Footer = () => (
  <footer className="footer">
    <Container>
      <Row className="d-flex justify-content-center">
        <img src="/image/logo_white.png" alt="회사 로고" className="logo" />
      </Row>
    </Container>
  </footer>
)

export default memo(Footer)
