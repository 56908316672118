import React, {memo} from "react"
import {Container, Nav, Navbar, Dropdown, NavItem} from 'react-bootstrap';
import {Link} from "gatsby"

function Header() {
  return (
    <header className="header">
      <Container>
        <Navbar collapseOnSelect expand="sm" className="topHeader">
          <Navbar.Brand href="/"><img src="/image/logo_black.png" alt="회사 로고" className="margin0 logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="MainMenu" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end MainMenu">
              <Nav>
                <Link to="/company-information" className="headerText nav-link">COMPANY</Link>
                <Link to="/recruit" className="headerText nav-link">RECRUIT</Link>
                <Link to="/#contact" className="headerText nav-link">CONTACT</Link>
              </Nav>
          </Navbar.Collapse>
          <Dropdown as={NavItem} alignRight className="ToggleMenu">
            <Dropdown.Toggle variant="light"><span className="navbar-toggler-icon"></span></Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to="/company-information" className="headerText dropdown-item" style={{height: '1.5em', borderBottomWidth: '1px', display: 'box'}}>COMPANY</Link>
              <Link to="/recruit" className="headerText dropdown-item" style={{height: '1.5em'}}>RECRUIT</Link>
              <Link to="/#contact" className="headerText dropdown-item" style={{height: '1.5em'}}>CONTACT</Link>
            </Dropdown.Menu>
          </Dropdown>          
        </Navbar>  
      </Container>  
    </header>);  
}

export default memo(Header);
